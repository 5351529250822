//custom varialbles start
$header-height: 74px;
$header-height-xs: 50px;
$footer-height: 72px;
$color1: #FF6900;
$color2: #F6993F;
$color3: #6E73FF;
$color4: #3596FF;
$color5: #BBBDBF;
$color6: #808184;

$primary:#0072ce;

$min-width: 375px;
// //custom varialbles end

//webfont install start
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-8Heavy.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-5Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-2ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-1Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-6Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-9Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-4Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-7ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Core Gothic D';
    src: url('/fonts/CoreGothicD-3Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
//webfont install end

html {
    width:100%;
    height: 100%;
    // overflow: hidden;
    overflow-y: overlay;
}

body {
    // display: table;
    width: 100%;
    // height: 100%;
    font-family: "Core Gothic D", "Noto Sans KR", sans-serif;
    font-size: 16px;
    font-weight: 500;

    a {
        color: #BBBDBF;
    }
}

#root {
    height: 100%;

    // @media (max-width: 991px) {
    //     height: 87%;
    // }
}

header {
    display: table-row;
    width: 100%;
    height: $header-height;
    min-width: $min-width;
    
    // navbar 고정
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: rgba(15, 41, 107, 0.15) 1rem -0.1rem 1rem;
    // navbar 고정

    @media (max-width: 991px) {
        height: $header-height-xs;
    }

    .header-inner {
        display: table-cell;
        width: 100vw;
        min-width: $min-width;
        height: inherit;
        vertical-align: middle;
        // border-bottom: solid 1px #ddd;

        .show>.btn-primary.dropdown-toggle {
            background-color: $color1;
            border-color: $color1;
        }

        .header-logo {
            @media (max-width: 991px) {
                text-align: center;
            }

            a {
                img {
                    height: 30px;

                    @media (max-width: 991px) {
                        height: 20px;
                    }
                }
            }
        }

        ul.main-menu {
            li {
                a {
                    font-size: 21px;
                    color: #555;
                    padding-left: 25px;
                    padding-right: 25px;
                }

                a.active {
                    color: $color1;
                    border-bottom: solid 3px $color2;
                }

                a:hover {
                    color:$color5
                }
            }
        }
    }
}

main {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    min-width: $min-width;

    @media (max-width: 991px) {
        padding-top: 0px;
        margin-top: 3rem;
        min-height: calc(100vh - 120px);
    }
}

footer {
    // display: table-row;
    width: 100%;
    height: $footer-height;
    min-width: $min-width;

    .footer-inner {
        // display: table-cell;
        width: 100%;
        height: inherit;
        vertical-align: middle;
        font-size: 12px;
        color: #ccc;
        padding: 18px 0px;
        background: #333333;

        @media (max-width: 991px) {
        color: $color5;
        font-size: 9px;
        line-height: 13px;
        padding: 10px 0px;
        }
    }
}

.d-lg-block{
    //color:$primary;
}
.d-lg-none{
    color:$primary;
}
.qr-text{
    font-size: 20px;
}
.container.margin-container {
    padding-right: 0;
    padding-left: 0;
}

.row.margin-row {
    margin-right: 0;
    margin-left: 0;
}

.margin-col {
    padding-right: 0;
    padding-left: 0;
}

.container-xl, .container-lg, .container-md, .container-sm, .container {
    @media (min-width: 1200px) {
      max-width: 1270px;
    }
}

.flex {
    display: flex;
}
.col-width-3{
    width: 29%;
}
.col-width-4{
    width: 42%;
}

.modal-content {
    min-width: $min-width;
}

.sidebar {
    
    .modal-header .btn-close {
        margin : 0;
    }

    .modal-dialog {
      margin: 0 80px 0 0;
  
      .modal-content {
        width: 80%;
        height: 100vh;
      }
    }
}

.spinner-border {
    animation-duration: 0.8s;
}

.modal-fullscreen-md-down {
    @media(max-width: 767.98px) {
        height: auto;
    }
}

.verticle-center {
    display: flex;
    align-items: center;
}

.form-find-password {
    input {
        margin-right: 10px;
    }
}

input,
select {
  border-radius: 0 !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color5 !important;
    opacity: 1 !important; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color5 !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: $color5 !important;
}
a {
    cursor: pointer;
    color: darken;
}
a:hover {
    color: darken;
    text-decoration: none;
}
a:focus,
button:focus,
input:focus,
textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}
.form-check-input,
.form-check-label {
  cursor: pointer;
}

.hide {
    display: none;
}

.background-rule {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.color1 {
    color: $color1;
}
.primary {
    color: $primary;
}

.navigator {
    font-weight: 300;
    font-size: 30px;
    // padding: 50px 0px;

    @media (max-width: 991px) {
      font-size: 20px;
    }

    .prev,
    .next {
      color: #ddd !important;
      padding: 0px 30px;
    }

    .btn {
        font-weight: 300;
        font-size: 30px;
    }

    .prev {
      background-image: url("/img/arrow-prev.svg");
      background-position: left;
      background-size: auto;
      background-repeat: no-repeat;
    }

    .next {
      background-image: url("/img/arrow-next.svg");
      background-position: right;
      background-size: auto;
      background-repeat: no-repeat;
    }

    .prev.active {
      color: $color1 !important;
      background-image: url("/img/arrow-prev-active.svg");
      background-position: left;
      background-size: auto;
      background-repeat: no-repeat;
    }

    .next.active {
      color: $primary !important;  //$color1 !important;
      background-image: url("/img/arrow-next-active.svg");
      background-position: right;
      background-size: auto;
      background-repeat: no-repeat;
      border-color: #FFFFFF;
    }
}

.btn-main {
    //background: linear-gradient(253.2deg, $color2 0%, $color1 99.46%);
    background: linear-gradient(253.2deg, #3395fd 0%, $primary 99.46%);
    color: white;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    min-width: 100px;
    border-radius: 5rem;

    @media (min-width: 992px) {
        padding: 0.5rem 2.5rem;
        font-size: 1.25rem;
        line-height: 1.5;
    }
}

.text-left {
    text-align: left;
}

.min-table {
    min-width: 1200px;
}

.btn-gray {
    background: $color6;
    color: rgb(40, 36, 36);
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    color: white;
    min-width: 100px;
  
    @media (min-width: 922px) {
      padding: 0.5rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
    }
}

.btn-radius-gray {
    background: $color6;
    color: rgb(40, 36, 36);
    border-width: 0px;
    border-radius: 10%;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
    min-width: 100px;
    @media (min-width: 922px) {
      padding: 0.3rem 1rem;
      font-size: 1rem;
      line-height: 1.5;
    }
}
.btn-radius-gray:hover{
    background-color: #555c63;
}

.btn-sub {
    background: linear-gradient(253.2deg, $color4 0%, $color3 99%);
    color: white;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    
    @media (min-width: 992px) {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
    }
}

.text-evplug {
    color: $primary;
}
.text-evplug-underline {
    color: $primary;
    text-decoration: underline;
}

.btn-login {
    font-size: 14px;
    background: white;
    color: $color6;
    border: 1px solid $color5;
    border-radius: 18px;

    @media (max-width: 991px) {
        font-size: 12px;
    }
}


.ml-2 {
    margin-left: 0.5rem !important;
}

.btn-search {
    font-size: 14px;
    background: white;
    color: $color6;
    border: 1px solid $color5;
    @media (max-width: 991px) {
        font-size: 12px;
    }
}
.btn-chargeking {
    background:$primary;
}
.btn-login:hover {
    background: $color6;
    color: white;
}

.text-right {
    text-align: right;
}

.item-center {
    display: flex;
    justify-content: center;
}

.item-right {
    display: flex;
    justify-content: right;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: #000;
}

.page-link {
    color: $color1;
}

.page-item.active .page-link {
    background-color: $color1;
    border-color: $color1;
}


a {
    color: #555;
    text-decoration: none;
    background-color: transparent;
}

.creditcard-store {
    font-size: 14px;
}

.btn {
    font-weight: 500;
}

.paddingTop10 {
    padding-top:10px;
}

// #__daum__layer_1 {
//     position: unset !important;
// }

.address .modal-content :nth-child(2){
    height: 446px !important;
}

.modal-title {
    font-size: 17px;
}

.btn-center {
    margin:auto;
    display: block;
}

hr.hr-gray {
    border-color: #ddd;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.btns .btn {
    margin-right: 5px;
}

small {
    font-weight: 400;
}

.trans-box {
    background: rgba(255, 255, 255, 0.93);
    padding: 50px;
    width: fit-content;
    margin: auto;
  
    @media (max-width: 991px) {
      padding: 50px 20px !important;
      margin: auto!important;
    }
  
    .btn-subBox {
      background-color: white;
      border: 1px solid #ddd;
      font-size: 30px;
      color: #333;
      padding: 35px;
      word-break: keep-all;
      width: 100%;
  
      @media (max-width: 991px) {
        font-size: 20px !important;
        padding: 15px;
      }
  
      img {
        @media (max-width: 991px) {
          width: 73px;
          padding-bottom: 16px;
        }
      }
    }

    .form-group {
        margin-bottom: 10px;
    }
  
    .btn-subBox:hover {
      background-color: white;
      border-color: $primary; //$color1;
      color: $primary; //$color1;
    }
}

input.form-check-input {
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid $color5;
    margin-left: -17px;
    margin-top: 0px;
}

.form-check-input:checked {
    background-color: #ff6900;
    border-color: #ff6900;
}

.login.modal {
    @media (max-width: 991px) {
        margin: 0;
    }
}
.is-invalid {

}
.isvalid {
    background-color: #FFFFFF;
    padding-top: 0rem;
    padding-bottom: 0rem;
    font-size: 0.9rem;
}
main.index {
    .section-1 {
        background-image: url("/img/img-visual.png");
        background-position: center top;
        height: 600px;
        padding: 149px;

        @media (max-width: 991px) {
            height: 380px;
            padding: 43px;
            padding-top: 60px;
        }

        .section-1-title {
            font-weight: 600;
            font-size: 42px;
            text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
            margin-bottom: 70px;

            @media (max-width: 991px) {
                font-size: 28px;
                margin-bottom: 20px;
            }
        }

        .section-1-content-left {
            border-image-width: 0 1px 0 0;
            border-style: solid;
            border-image-source: url("/img/line.png");
            border-image-slice: 1;
            text-align: right;
            padding-right: 30px;

            @media (max-width: 991px) {
                border-image-width: 0;
                text-align: center;
                padding: 0;
            }

            img {
                @media (max-width: 991px) {
                  width: 174px;
                  margin-bottom: 20px;
                }
            }
        }
        
        .section-1-content-right {
            font-size: 22px;
            text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.6);
            line-height: 40px;
            padding-left: 20px;
            font-weight: 600;

            @media (max-width: 991px) {
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                padding: 0;
            }
        }
        .section-1-text{
            line-height: 2.5;
        }
    }

    .section-2 {
        padding: 100px 0;

        @media (max-width: 991px) {
            padding: 60px 0;
        }

        .section-2-title {
            font-weight: 600;
            font-size: 36px;
            color: #111;
            margin-top: 30px;
            
            @media (max-width: 991px) {
                font-size: 20px;
                line-height: 24px;
                margin-top: 12px;
            }
        }

        img {
            @media (max-width: 991px) {
              height: 50px;
            }
        }

        .section-2-content {
            font-size: 20px;
            color: #555;
            margin-top: 18px;

            @media (max-width: 991px) {
                font-size: 13px;
                line-height: 20px;
                margin-top: 9px;
            }
        }
        
        .btn-main {
            margin-top: 30px;
            
            @media (max-width: 991px) {
                margin-top: 15px;
                margin-bottom: 60px;
            }
        }

        .btn-sub {
            margin-top: 30px;
            @media (max-width: 991px) {
                margin-top: 15px;
            }
        }
    }

    .section-3 {
        padding: 100px 0;
        background: linear-gradient(114.36deg, #FAFAFF 0%, #F2F1FB 100%);

        @media (max-width: 991px) {
            padding: 60px 0;
        }

        .btn-main {
            padding: 16px 43px;
        }
    }

    .section-4 {
        padding: 100px 0;

        @media (max-width: 991px) {
            padding: 60px 0;
        }

        .section-4-title {
            font-size: 36px;
            font-weight: 600;
            color: #111;
            margin-bottom: 28px;

            @media (max-width: 991px) {
                font-size: 20px;
            }
        }

        .btn-more {
            top: 14px;
            right: 0;
            font-size: 21px;
            font-weight: normal;
            color: #555;
            text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);

            @media (max-width: 991px) {
                top: 2px;
                font-size: 12px;
            }

            img {
                @media (max-width: 991px) {
                  width: 17px !important;
                }
            }
        }

        .list-board {
            font-size: 1.10rem;
            
            .list-head {
                font-weight: 500;
                border-color: rgba(17, 17, 17, 0.8);
                border-width: 2px 0 0 1px;
                border-top: 2px solid #000;
                border-bottom: 1px solid #707070;
                padding: 10px 10px 10px 10px;
                
                @media (max-width: 991px) {
                    font-size: 15px;
                }
            }

            .list:hover {
                background: rgb(250, 250, 250);
            }

            a {
                display: table;
                table-layout: fixed;
                border-collapse: collapse;
                width: 100%;
                border-bottom: 1px solid #ebebeb;
            }

            .list-subject {
                padding: 10px 10px 10px 10px;
                color: #1c272b;

                @media (max-width: 991px) {
                    font-size: 13px;
                    margin-top: 2px;
                }
            } 

            .badge {
                margin-right: 2%;
            }
        }
    }

    .section-5 {
        background-image: url("/img/img-bg-full-main.png");
        background-position: center bottom;

        .box {
            background: rgba(255, 255, 255, 0.8);
            padding: 37px;
            width: 35%;

            @media (max-width: 991px) {
                min-width: 80%;
                margin-bottom: 20px;
            }

            .section-5-title {
                font-weight: 600;
                font-size: 36px;
                color: #111;
                margin-top: 30px;

                @media (max-width: 991px) {
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 12px;
                }
            }

            .section-5-content {
                font-size: 20px;
                color: #555;
                margin-top: 18px;

                @media (max-width: 991px) {
                    font-size: 13px;
                    line-height: 20px;
                    margin-top: 9px;
                }
            }
            .section-5-content2 {
                padding:10%;
            }
            .icon-image {
                width: 50%;
                max-width: 15vh;
                border-radius: 25%;
                margin-top: 18px;
            }

            .btn-image {
                width: 20vh;
                // @media (max-width: 991px) {
                //     height: 60px;
                // }
            }
        }
    }
}

main.login {
    background-image: url("/img/img-bg-full.png");

    // @media (max-width: 991px) {
    //     padding: 43px 0;
    // }

    @media (max-width: 991px) {
        background: none;
    }

    .login-box {
        background: white;
        padding: 40px;
        // width: inherit;
        margin: auto;
        border: 1px solid #ddd;
        // margin-top: 145px;
        margin-top: 50%;

        @media (max-width: 991px) {
            margin-top: 0 !important;
            border: 0;
        }

        .btn-me {
            background: white;
            color: $primary;
            border: 1px solid $primary;
            border-radius: 18px;
            font-size: 14px;
            
            @media (max-width: 991px) {
                font-size: 12px;
            }
        }
        .btn-me:hover {
            background: $primary;
            color: white;
            border: 1px solid $primary;
        }

        table {
            td {
                border: 1px solid $color5;
    
                input {
                    width: 100%;
                    height: 40px;
                    border: none;
                    font-size: 18px;
                    padding: 0 10px;
                }
            }

            .error_msg {
                border: none !important;
            }
    
            th {
                border: 1px solid $primary;
                padding: 0;
    
            
                // @media (min-width: 992px) {
                //     padding: 0.5rem 1rem;
                //     font-size: 1.25rem;
                //     line-height: 1.5;
                // }


                .btn-loginPage {
                    width: 100px;
                    height: 90px;
                    font-size: 22px;
                    font-weight: 400;
                    color: white;
                    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
                    background: $primary; //linear-gradient(263.18deg, $color2 0%, $color1 99.46%);
                    border: 0px;
                    border-radius: 0;
                    padding-top: 10px;
                }

                // .btn-loginPage:hover {
                //     color: #333333;
                // }
            }        
        }
    
        .text-right a {
            text-decoration: none;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #555555;
        }
    
        .text-right a:hover{
            color: $color5;
        }
    }
}

main.register {
    background-image: url("/img/img-bg-full-join.jpg");
    height: 100%;

    @media (max-width: 991px) {
        background: none;
    }

    .trans-box {
        padding: 40px;
        // margin-top: 145px;
        margin-top: 20vh;
        margin-bottom: 186px;
    
        @media (max-width: 991px) {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          background: white;
        }
    }

    ul {
        font-size: 15px;
        color: #555;
        padding-left: 15px;
    
        @media (max-width: 991px) {
          font-size: 13px;
        }
    }
}


main.join {

    // .car {
    //     margin-bottom: 0px;
    // }

    .col-sm-3 {
        display: inline-flex;
        align-items: center;
    }

    .form-label {
        margin-bottom: 0;
    }

    .section-form {
        .form-group {
            margin-bottom: 15px;
        }
    }

    .check {
        margin-left: 5%;
    }

    // .form-group {
    //     margin-bottom: 1rem!important;
    // }

    .container {
        @media (min-width: 991px) {
            margin-top: 5rem;
        }
    }

    .agree-box {
        .form-check {
            padding-left: 0.5em;
        }
    }

    // .form-check {
    //     padding-left: 0.5em;
    // }

    // @media (max-width: 991px) {
    //     padding: 43px 0;
    // }

    .tail-button {
        padding-left: 0;
    }

    .padding-if {
        padding-left: 20px;
        padding-right: 20px;
    
        @media (max-width: 991px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    
        .agree-title {
            font-weight: 500;
            font-size: 22px;
            color: #333333;
    
            @media (max-width: 991px) {
                font-size: 15px !important;
                line-height: 18px !important;
                float: left;
            }
        }

        // .form-check{
        //     display: block;
        //     min-height: 1.5rem;
        //     margin-bottom: 0.125rem;
        // }

        .all-agree {
            // padding-left:16px;
            padding-left: 0;
        }

       
        .agree-box {
            font-weight: 400;
            position: absolute;
            bottom: 0;
    
            @media (max-width: 991px) {
                right: 15px;
                top: 3px;
                float: right;
            }
        }
    
        .all-agree-box {
            @media (max-width: 991px) {
                // text-align: right;
                // right: 15px;
                padding-right: 1rem;
                top: 3px;
                float: right;
            }
        }
    
        .div-relative {
            position: relative;
            
            @media (max-width: 991px) {
                padding-bottom: 10px;
            }
        }

        .div-all-padding {
            padding-left: 4px;
            padding-right: 0;
        }
    }

    .navigator {
        padding: 50px 0px;
    }

    .join-step {
        .btn {
          font-size: 20px;
          line-height: 24px;
          border: solid 1px #ddd;
          color: #555;
          padding: 18px 74px;
          cursor: default;
    
          @media (max-width: 991px) {
            font-size: 14px;
            line-height: 16px;
            padding: 8px 18px;
          }
        }
    
        .btn:hover {
          background: white;
          border: solid 1px #ddd;
          color: #555;
          font-weight: 400;
        }
    
        .btn.active {
          background: white;
          border: 1px solid $color4;
          color: $color4;
          font-weight: 500;
        }
    
        .btn-rounded.active {
          border: 1px solid $color4;
          background-color: white !important;
          color: $color4 !important;
          font-weight: 500 !important;
        }
    
        .btn-rounded:first-child {
          border-top-left-radius: 35px !important;
          border-bottom-left-radius: 35px !important;
        }
    
        .btn-rounded:last-child {
          border-top-right-radius: 35px !important;
          border-bottom-right-radius: 35px !important;
        }
    }
}





.nice-dates-navigation, .nice-dates-day {
    color: #111;
}

.nice-dates-popover {
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 2px;
    max-width: 300px;
    transition: none;
}

.date-range {
    display: flex;
}

.date-range_arrow {
    // font-size: 23px;
    font-weight: 300;
}

.picker {
    margin-top: 1rem;

    input {
        height: 40px;
    }

    button {
        height: 40px;
        padding: 0px;
    }
}

.begin-dt {
    margin-right: 10px;
}

.end-dt {
    margin-left: 10px;
    margin-right: 10px;
}

.iframe {
    overflow: auto;
    height: 157px;

    .spinner-border {
        margin-top: 60px;
    }
}

// .iframe::-webkit-scrollbar-track {
//     background-color: #F1F1F1;
// }

// .iframe::-webkit-scrollbar {
//     width: 8px;
//     background-color: #C1C1C1;
// }

// .iframe::-webkit-scrollbar-thumb {
//     background-color: #C1C1C1;
// }

.section-form {
    label {
      font-weight: 500;
      font-size: 14px;
    }

    .notice-member {
      font-size: 14px;
      margin-top: 63px;
      margin-bottom: 63px;

      @media (max-width: 991px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .notice-company {
      font-size: 14px;
      margin-top: 53px;
      margin-bottom: 53px;

      @media (max-width: 991px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
}

.lst_sty, .lst_dot {
    overflow:hidden;
    //color:#666;
    line-height: 1.6em;
    list-style-type: none;
    font-weight: 400;

    @media (max-width: 991px) {
      padding: 0;
    }
}

.lst_sty>*, .lst_dot>* {
    position:relative;
    padding-left:1.1em;
  }
  .lst_sty>span {
    display:inline-block;
  }
  .lst_sty.mus>* {
    padding-left:0.8em;
  }
  .lst_sty.no>*, .lst_dot.no>* {
    padding-left:0;
  }
  .lst_sty>*:before, .lst_dot>*:before {
    position:absolute;
    left:0;
    content:"\203B";
}

.lst_sty2 {
    line-height: 1.6em;
    font-weight: 500;

    @media (max-width: 991px) {
      padding: 0;
    }
}

.charger-info {
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 31px;
      color: #333333;
    }
  
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
    }
  
    table {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #555555;
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
      border-bottom: solid 1px #BBBBBB;
      width: 100%;
      text-align: center;
      vertical-align: middle;
  
      td,
      th {
        padding: 15px 15px 15px 15px;
      }
  
      th {
        font-weight: normal;
        background: #F6F6F6;
      }
  
      td {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: $color4;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
      }
  
      th.first {
        border-right: solid 1px #bbbbbb!important;
      }

      .border-right {
        border-right: solid 1px #bbbbbb!important;
      }
  
      .unit {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: #555555;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
      }
    }
  
    .info-last-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }
  }

main.common-index {

    .invalid-feedback {
        font-size: 15px;
    }

    .startDt {
        margin-right: 10px;
    }
    
    @media (max-width: 991px) {
        padding: 43px 0;
    }

    .min-table .list-head .row{
        font-size: 14px;
    }

    .not-found {
        @media (min-width: 991px) {
            margin-top: 8rem;
        }
    }

    .charging {
        .list-board {
            overflow-x: auto;
        }

        @media (min-width: 991px) {
            margin-top: 8rem;
        }
    }

    .section-4 {
        @media (min-width: 991px) {
            margin-top: 8rem;
        }
    }
    
    .detail-content {
        img {
            max-width: 100% !important;
            height: auto !important;
        }

        tr {
            border-width: 1px;
        }

        td {
            border-width: 1px;
        }
    }

    .section-4-title {
        font-size: 1.75rem;
        font-weight: 500;
        color: #111;
        margin-bottom: 28px;

        @media (max-width: 991px) {
            font-size: 20px;
        }
    }

    // .subsidy {
    //     line-height: 25px;
    //     width: 100%;
    //     border-collapse: collapse;
    //     color: #555;
    //     font-size:16px;

    //     .head {
    //         border-top: 2px solid #000;
    //     }

    //     tbody {
    //         // border-top: 2px solid #000;
    //         border-top-color: rgba(17, 17, 17, 0.8);
    //         padding: 0.75rem;
    //         vertical-align: middle;
    
    //         th {
    //             background: #f7f7f7;
    //             font-weight: 400;
    //             font-size: 16px;
    //             width: 25%;
    //         }

    //         td {
    //             color: $color4;
    //             font-weight: 600;
    //             font-size: 18px;
    //         }
    //     }
    // }

    .list-board {
        font-size: 16px;
        // min-width: 1200px;
      
        .detail-content a {
            color: #1c272b;
        }

        .list-head {
            font-weight: 500;
            font-size: 15px;
            background: #f7f7f7;
            border-color: rgba(17, 17, 17, 0.8);
            border-width: 2px 0 0 1px;
            border-top: 2px solid #000;
            border-bottom: 1px solid #707070;
            padding: 13px 10px 13px 10px;
            
            @media (max-width: 991px) {
                font-size: 15px;
            }
        }

        .list:hover {
            background: rgb(250, 250, 250);
        }

        .list a {
            display: table;
            table-layout: fixed;
            border-collapse: collapse;
            width: 100%;
            border-bottom: 1px solid #ebebeb;
        }

        .a {
            display: table;
            table-layout: fixed;
            border-collapse: collapse;
            width: 100%;
            border-bottom: 1px solid #ebebeb;
        }

        .list-subject {
            padding: 10px 10px 10px 10px;
            color: #1c272b;
            font-weight: 400;

            @media (max-width: 991px) {
                font-size: 13px;
            }
        } 

        .badge {
            margin-right: 2%;
        }
    }

    .detail-date {
        font-weight: 500;
        color: $color6;
    }

    .detail-head {
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .detail-content {
        padding: 30px 10px 10px 10px;
    }

    .detail-bottom {
        padding-bottom: 20px;
    }
}

.stipulation {
    @media (min-width: 991px) {
        margin-top: 8rem;
    }
}

main.reception {
    @media (min-width: 991px) {
        margin-top: 8rem;
    }

    .form-check-input[type=radio] {
        border-radius: 50% !important;
    }

    // .form-check {
    //     padding-left: 0;
    // }

    h3 {
        font-weight: 500;
    }

    @media (max-width: 991px) {
        background: none;
        margin-top: 5rem;
    }

    // margin-top: 63px;

    .card {
        opacity:0.9;
    }

    .reception-guide {
        justify-content: center;
    }
    .test {
        display: flex;
    }

    .notice {
        font-size: 20px;
        span {
            color:$color4;
        }
    }

    .top-box {
        margin-right: 2%;
        background: whte;
        border: 1px solid #DDDDDD;
        padding: 40px 23px;
        text-align: center;
        height: 313px;
        border-radius: 10px;
    }

    .guide {
        // margin-top: 20px;
    }
    
    .sss {
        // border: 1px solid #DDDDDD;
        border-left: 1px solid #efefef;
    }

    section {
        // background: #F1F1F1;
        // background-image: url("/img/img-charger-info-bg.png");   
        width: 100%;
    }

    .reception-form {
        // margin: 0;
        width: 87%;
        // margin-top: 3rem;
    }

    .reception-card {
        font-weight: 400;
        padding: 1rem;
        border-radius: 8px 8px 8px 8px;
    }

    table {
        overflow: hidden;
    }

    table tbody th {
        width: 20%;
    }

    th {
        font-weight: 500;
        vertical-align: middle;
    }

    td {
        .form-group {
            margin-bottom: 0rem !important;
        }
    }

    .badge {
        background-color: $color2 !important;
    }

    // span {
    //     color: $color4;
    // }
}

.test {
    background-color: $color5;
    height: 20vh;
    text-align: center;
    // margin-top: 1rem;
}

main.me-index {
    @media (max-width: 991px) {
        padding: 43px 0;
    }

    .mr-3 {
        margin-right: 30px;
    }

    .section-4 {
        @media (min-width: 991px) {
            margin-top: 8rem;
        }
    }

    .section-4-title {
        font-size: 1.75rem;
        font-weight: 500;
        color: #111;
        margin-bottom: 28px;

        @media (max-width: 991px) {
            font-size: 20px;
        }
    }

    tbody {
        border-top: 2px solid #000;
        border-top-color: rgba(17, 17, 17, 0.8);
        padding: 0.75rem;

        th {
            vertical-align: middle;
            background: #f7f7f7;
            font-weight: 500;
            font-size: 14px;
            width: 25%;
        }
    }

    li { 
        font-size: 15px;
        font-weight: 400;

        a {
            color:#00f;
        }
    }
}

main.qrpay {
    background-image: url("/img/img-bg-full-apply.jpg");

    // .col-4 {
    //     display: inline-flex;
    //     align-items: center;
    // }


    .col-form-label {
        padding-bottom: 0 !important;
    }

    h3 {
        font-weight: 600;
    }

    @media (max-width: 991px) {
        background: none;
        margin-top: 5rem;
    }

    .form-check-input[type=radio] {
        border-radius: 50% !important;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .pay {
        width: 100%;
    }

    .card {
        opacity:0.9;
    }

    .qrpay-guide {
        justify-content: center;
    }

    .section-agree {
        .form-check-label {
            font-weight: 500;
        }

        .iframe {
            font-size: 13px;
            font-weight: 500;
        }
    }

    small {
        font-size: 80%;
        font-weight: 500;
    }

    .green-circle {
        border-radius: 50%;
        height: 15px; 
        width: 15px; 
        background-color:  #27AE60;
    }

    .orange-circle {
        border-radius: 50%;
        height: 15px; 
        width: 15px; 
        background-color:  #F2994A;
    }

    sub {
        padding-left: 15px;
        color: #2C98F0;
    }

    .top-box {
        width: 60%;
        background: white;
        opacity:0.93; 
        padding: 40px 50px 23px 50px;
        border-radius: 5px;

        @media (min-width: 991px) {
            margin-top: 8rem;
        }

        @media (max-width: 991px) {
            width: 100%;
        }
    }
    
    .reception-form {
        width: 87%;
    }
}


// .section-reception {
//     @media (min-width: 991px) {
//         margin-top: 8rem;
//     }
// }




// main.index {
//   .section-1 {
//     background-image: url("/img/img-visual.jpg");
//     height: 600px;
//     padding: 149px;

//     @media (max-width: 991px) {
//       height: 380px;
//       padding: 43px;
//     }

//     .section-1-title {
//       font-weight: 500;
//       font-size: 42px;
//       text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
//       margin-bottom: 70px;

//       @media (max-width: 991px) {
//         font-size: 28px;
//         margin-bottom: 20px;
//       }
//     }

//     .section-1-content-left {
//       border-image-width: 0 1px 0 0;
//       border-style: solid;
//       border-image-source: url("/img/line.png");
//       border-image-slice: 1;
//       text-align: right;
//       padding-right: 30px;

//       @media (max-width: 991px) {
//         border-image-width: 0;
//         text-align: center;
//         padding: 0;
//       }

//       img {
//         @media (max-width: 991px) {
//           width: 174px;
//           margin-bottom: 20px;
//         }
//       }
//     }

//     .section-1-content-right {
//       font-size: 22px;
//       text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
//       line-height: 40px;
//       padding-left: 20px;

//       @media (max-width: 991px) {
//         font-size: 14px;
//         line-height: 22px;
//         text-align: center;
//         padding: 0;
//       }
//     }
//   }

//   .section-2 {
//     padding: 100px 0;

//     @media (max-width: 991px) {
//       padding: 60px 0;
//     }

//     .section-2-title {
//       font-weight: 600;
//       font-size: 36px;
//       color: #111;
//       margin-top: 30px;

//       @media (max-width: 991px) {
//         font-size: 20px;
//         line-height: 24px;
//         margin-top: 12px;
//       }
//     }

//     img {
//       @media (max-width: 991px) {
//         height: 50px;
//       }
//     }

//     .section-2-content {
//       font-size: 20px;
//       color: #555;
//       margin-top: 18px;

//       @media (max-width: 991px) {
//         font-size: 13px;
//         line-height: 20px;
//         margin-top: 9px;
//       }
//     }

//     .part-joinBtn {
//       margin-top: 30px;

//       @media (max-width: 991px) {
//         margin-top: 15px;
//         margin-bottom: 60px;
//       }
//     }

//     .part-installBtn {
//       margin-top: 30px;

//       @media (max-width: 991px) {
//         margin-top: 15px;
//       }
//     }
//   }

//   .section-3 {
//     padding: 100px 0;
//     background: linear-gradient(114.36deg, #FAFAFF 0%, #F2F1FB 100%);

//     @media (max-width: 991px) {
//       padding: 60px 0;
//     }

//     .section-3-title {
//       font-size: 36px;
//       font-weight: 600;
//       color: #111;

//       @media (max-width: 991px) {
//         font-size: 20px;
//       }
//     }

//     .section-3-content {
//       font-size: 20px;
//       color: #555;
//       margin-top: 16px;

//       @media (max-width: 991px) {
//         font-size: 13px;
//         margin-top: 12px;
//       }
//     }

//     .section-sub {
//       padding: 50px 0;

//       @media (max-width: 991px) {
//         padding: 20px 0;
//       }

//       .section-3-subTitle {
//         font-weight: 600;
//         font-size: 30px;
//         color: #111111;
//         margin-top: 110px;

//         @media (max-width: 991px) {
//           font-size: 16px;
//           line-height: 19px;
//           margin-top: 80px;
//         }
//       }

//       .section-3-subContent {
//         font-size: 16px;
//         color: #555;
//         margin-top: 13px;

//         @media (max-width: 991px) {
//           font-size: 12px;
//           margin-top: 6px;
//         }
//       }
//     }

//     .part-joinBtn {
//       margin-top: 50px;

//       @media (max-width: 991px) {
//         margin-top: 50px;
//       }
//     }
//   }

//   .section-4 {
//     padding: 100px 0;

//     @media (max-width: 991px) {
//       padding: 60px 0;
//     }

//     .section-4-title {
//       font-size: 36px;
//       font-weight: 600;
//       color: #111;
//       margin-bottom: 28px;

//       @media (max-width: 991px) {
//         font-size: 20px;
//       }

//       .btn-more {
//         top: 14px;
//         right: 0;
//         font-size: 21px;
//         font-weight: normal;
//         color: #555;
//         text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);

//         @media (max-width: 991px) {
//           top: 2px;
//           font-size: 12px;
//         }

//         img {
//           @media (max-width: 991px) {
//             width: 17px !important;
//           }
//         }
//       }
//     }

//     .part-board {
//       @media (max-width: 991px) {
//         border-top: 2px solid rgba(17, 17, 17, 0.8);
//       }

//       .board-head {
//         color: #555;
//         border-color: rgba(17, 17, 17, 0.8);
//         border-width: 2px 0 0 1px;
//       }

//       .board-body {
//         border-color: #bbb;

//         .part-title {
//           color: #333;

//           @media (max-width: 991px) {
//             border-bottom: 0 !important;
//             // padding-bottom: 0;
//           }
//         }

//         .part-date {
//           color: #555;

//           @media (max-width: 991px) {
//             font-size: 12px;
//             text-align: left !important;
//             padding-top: 0 !important;
//           }
//         }
//       }
//     }
//   }

//   .section-5 {
//     background-image: url("/img/img-bg-full-main.jpg");

//     .box {
//       background: rgba(255, 255, 255, 0.85);
//       padding: 37px;

//       .section-5-title {
//         font-weight: 600;
//         font-size: 36px;
//         color: #111;
//         margin-top: 30px;

//         @media (max-width: 991px) {
//           font-size: 20px;
//           line-height: 24px;
//           margin-top: 12px;
//         }
//       }

//       img.thumb {
//         @media (max-width: 991px) {
//           height: 50px;
//         }
//       }

//       .section-5-content {
//         font-size: 20px;
//         color: #555;
//         margin-top: 18px;

//         @media (max-width: 991px) {
//           font-size: 13px;
//           line-height: 20px;
//           margin-top: 9px;
//         }
//       }

//       .btn-call {
//         @media (min-width: 992px) {
//           padding: 0.8rem 2.5rem;
//         }
//       }
//     }
//   }
// } //main.index

// main.register {
//   background-image: url("/img/img-bg-full-join.jpg");

//   @media (max-width: 991px) {
//     background: none;
//   }

//   .trans-box {
//     padding: 40px;
//     margin-top: 145px;
//     margin-bottom: 186px;

//     @media (max-width: 991px) {
//       margin-top: 0 !important;
//       margin-bottom: 0 !important;
//       background: white;
//     }
//   }

//   ul {
//     font-size: 15px;
//     color: #555;
//     padding-left: 15px;

//     @media (max-width: 991px) {
//       font-size: 13px;
//     }
//   }
// } //main.register

// main.join {
//   .join-step {
//     .btn {
//       font-size: 20px;
//       line-height: 24px;
//       border: solid 1px #ddd;
//       color: #555;
//       padding: 18px 74px;
//       cursor: default;

//       @media (max-width: 991px) {
//         font-size: 14px;
//         line-height: 16px;
//         padding: 8px 18px;
//       }
//     }

//     .btn:hover {
//       background: white;
//       border: solid 1px #ddd;
//       color: #555;
//       font-weight: 400;
//     }

//     .btn.active {
//       background: white;
//       border: 1px solid $color4;
//       color: $color4;
//       font-weight: 500;
//     }

//     .btn-rounded.active {
//       border: 1px solid $color4;
//       background-color: white !important;
//       color: $color4 !important;
//       font-weight: 500 !important;
//     }

//     .btn-rounded:first-child {
//       border-top-left-radius: 35px !important;
//       border-bottom-left-radius: 35px !important;
//     }

//     .btn-rounded:last-child {
//       border-top-right-radius: 35px !important;
//       border-bottom-right-radius: 35px !important;
//     }

//     // .btn.clikable:hover {
//     //   background: white;
//     //   border: 1px solid $color4;
//     //   color: $color4;
//     //   font-weight: 500;
//     // }
//   } //main.join

//   .navigator {
//     font-weight: 300;
//     font-size: 30px;
//     padding: 50px 0px;

//     @media (max-width: 991px) {
//       font-size: 20px;
//     }

//     .prev,
//     .next {
//       color: #ddd !important;
//       padding: 0px 30px;
//     }

//     .prev {
//       background-image: url("/img/arrow-prev.svg");
//       background-position: left;
//       background-size: auto;
//       background-repeat: no-repeat;
//     }

//     .next {
//       background-image: url("/img/arrow-next.svg");
//       background-position: right;
//       background-size: auto;
//       background-repeat: no-repeat;
//     }

//     .prev.active {
//       color: $color1 !important;
//       background-image: url("/img/arrow-prev-active.svg");
//       background-position: left;
//       background-size: auto;
//       background-repeat: no-repeat;
//     }

//     .next.active {
//       color: $color1 !important;
//       background-image: url("/img/arrow-next-active.svg");
//       background-position: right;
//       background-size: auto;
//       background-repeat: no-repeat;
//     }
//   }

//   #section-agree {
//     .padding-if {
//       padding-left: 20px;
//       padding-right: 20px;

//       @media (max-width: 991px) {
//         padding-left: 0px;
//         padding-right: 0px;
//       }

//       .agree-title {
//         font-weight: 500;
//         font-size: 22px;
//         color: #333333;

//         @media (max-width: 991px) {
//           font-size: 15px !important;
//           line-height: 18px !important;
//         }
//       }

//       .agree-box {
//         bottom: 0;

//         @media (max-width: 991px) {
//           right: 15px;
//           top: 3px;
//         }
//       }

//       .all-agree-box {
//         @media (max-width: 991px) {
//           text-align: right;
//         }
//       }
//     }

//     iframe::-webkit-scrollbar-track {
//       background-color: #F1F1F1;
//     }

//     iframe::-webkit-scrollbar {
//       width: 8px;
//       background-color: #C1C1C1;
//     }

//     iframe::-webkit-scrollbar-thumb {
//       background-color: #C1C1C1;
//     }
//   }

//   #section-form {
//     .col-form-label {
//       font-weight: 500;
//       font-size: 14px;
//     }

//     .notice-member {
//       font-size: 14px;
//       margin-top: 63px;
//       margin-bottom: 63px;

//       @media (max-width: 991px) {
//         margin-top: 20px;
//         margin-bottom: 20px;
//       }
//     }

//     .notice-company {
//       font-size: 14px;
//       margin-top: 53px;
//       margin-bottom: 53px;

//       @media (max-width: 991px) {
//         margin-top: 20px;
//         margin-bottom: 20px;
//       }
//     }
//   }
// }

// main.login {
//   background-image: url("/img/img-bg-full.png");

//   @media (max-width: 991px) {
//     background: none;
//   }

//   .login-box {
//     background: white;
//     padding: 40px;
//     width: inherit;
//     margin: auto;
//     border: 1px solid #ddd;
//     margin-top: 145px;

//     @media (max-width: 991px) {
//       margin-top: 0 !important;
//       border: 0;
//     }

//     table {
//       td {
//         border: 1px solid $color5;

//         input {
//           width: 100%;
//           height: 40px;
//           border: none;
//           font-size: 18px;
//           padding: 0 10px;

//           @media (max-width: 991px) {
//             font-size: 14px;
//           }
//         }
//       }

//       th {
//         border-top: 1px solid $color2;
//         border-bottom: 1px solid $color2;
//         border-left: 1px solid $color2;
//         border-right: 1px solid white;
//         padding: 0;

//         .btn-loginPage {
//           width: 100px;
//           height: 90px;
//           font-size: 20px;
//           font-weight: 500;
//           color: white;
//           text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
//           background: linear-gradient(263.18deg, $color2 0%, $color1 99.46%);
//           border-radius: 0;
//           padding-top: 10px;

//           @media (max-width: 991px) {
//             font-size: 14px;
//           }
//         }
//       }
//     } //table

//     .text-right a {
//       text-decoration: none;
//       font-weight: normal;
//       font-size: 14px;
//       line-height: 16px;
//       text-align: right;
//       color: #555555;
//     }

//     .text-right a:hover {
//       color: $color5;
//     }
//   } //login-box
// } //main.login

// #modal-findId,
// #modal-findPw {
//   .modal-dialog {
//     @media (max-width: 991px) {
//       margin: 0;
//     }

//     .modal-content {
//       .modal-body {
//         padding: 0 1rem 1rem 1rem;

//         .btn-next {
//           font-size: 18px;
//           padding: 10px 15px;
//         }
//       }
//     }
//   }
// }

// main.reception {
//   background-image: url("/img/img-bg-full-apply.jpg");

//   @media (max-width: 991px) {
//     background: none;
//   }

//   .trans-box {
//     padding: 40px;
//     margin-top: 78px;
//     margin-bottom: 78px;

//     @media (max-width: 991px) {
//       margin-top: 0 !important;
//       margin-bottom: 0 !important;
//       background: white;
//     }
//   }
// }

// main.article-index {
//   table.articles {
//     thead {
//       tr {
//         th {
//           border-top: 2px solid rgba(17, 17, 17, 0.8);
//         }
//       }
//     }
//   }
// }

// main.article-show {
//   .article-content {
//     img {
//       max-width: 100% !important;
//       height: auto !important;
//     }
//   }
// }

// main.reception-complete {
//   padding-top: 100px;
//   padding-bottom: 322px;

//   .notice {
//     font-size: 20px;

//     @media (max-width: 991px) {
//       font-size: 15px;
//     }

//     span {
//       color: $color4;
//     }
//   }
// }

// main.chargings {
//   .section-filter-chargings {
//     background: #F7F7F7;

//     .btn-outline-hmain {
//       background: #fff;
//       border: 1px solid $color5;
//       font-size: 15px;
//       color: $color6;
//     }
//     .btn-outline-hmain.active,
//     .btn-outline-hmain:hover {
//       border: 1px solid $color1;
//       color: $color1;
//     }
//     .btn-outline-hmain.first {
//       border-top-left-radius: 50rem !important;
//       border-bottom-left-radius: 50rem !important;
//     }
//     .btn-outline-hmain.last {
//       border-top-right-radius: 50rem !important;
//       border-bottom-right-radius: 50rem !important;
//     }

//     .btn-filtering {
//       @media (min-width: 992px) {
//         height: 38px;
//         font-size: 15px;
//       }
//     }
//   }

//   .tui-datepicker {
//     top: 40px;
//     left: 70px;

//     @media (max-width: 991px) {
//       left: 25px;
//     }
//   }

//   .icon-cal {
//     background: white;
//     padding: 6px 10px;
//     border-top: 1px solid #ced4da;
//     border-right: 1px solid #ced4da;
//     border-bottom: 1px solid #ced4da;
//   }
// }


// //lsw start
// .charger-info {
//   h4 {
//     font-style: normal;
//     font-weight: 500;
//     font-size: 26px;
//     line-height: 31px;
//     color: #333333;
//   }

//   p {
//     font-style: normal;
//     font-weight: normal;
//     font-size: 18px;
//     line-height: 21px;
//     color: #333333;
//   }

//   table {
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 19px;
//     color: #555555;
//     text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
//     border-bottom: solid 1px #BBBBBB;
//     width: 100%;
//     text-align: center;

//     td,
//     th {
//       padding: 15px 0px;
//     }

//     th {
//       font-weight: normal;
//       background: #F6F6F6;
//     }

//     td {
//       font-weight: 500;
//       font-size: 18px;
//       line-height: 21px;
//       color: $color4;
//       text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
//     }

//     th.first {
//       border-right: solid 1px #bbbbbb!important;
//     }

//     .unit {
//       font-weight: normal;
//       font-size: 14px;
//       line-height: 16px;
//       text-align: right;
//       color: #555555;
//       text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
//     }
//   }

//   .info-last-text {
//     font-weight: normal;
//     font-size: 16px;
//     line-height: 19px;
//     color: #333333;
//   }
// }

// .charger-banner-1 {
//   background-image: url("/img/img-charger-info-bg.png");
//   background-size: cover;
//   background-position: center;

//   .top-box {
//     background: white;
//     border: 1px solid #DDDDDD;
//     padding: 40px 23px;
//     text-align: center;
//     height: 313px;

//     @media (max-width: 991px) {
//       padding: 20px 16px !important;
//       height: auto!important;
//     }

//     p {
//       font-weight: normal;
//       font-size: 18px;
//       line-height: 28px;
//       color: #555555;
//       padding: 30px 0 0 0;
//       margin: 0;
//       word-break: keep-all;

//       @media (max-width: 991px) {
//         font-size: 13px !important;
//         line-height: 18px !important;
//         padding: 0 !important;
//         text-align: left!important;
//       }
//     }

//     img {
//       max-width: 136px;
//       height: 89px;

//       @media (max-width: 991px) {
//         max-width: 80px !important;
//         height: 52px !important;
//       }
//     }

//     span {
//       color: $color4;
//     }
//   }
// }

// .charger-title1 {
//   font-weight: 500;
//   font-size: 28px;
//   line-height: 33px;
//   color: #333333;

//   @media (max-width: 991px) {
//     font-size: 18px !important;
//     line-height: 21px !important;
//   }
// }

// .charger-title2 {
//   font-style: normal;
//   font-weight: normal;
//   font-size: 20px;
//   line-height: 24px;
//   color: #333333;
// }

// .charger-info-table-header {
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 21px;
//   color: #333333;
//   border-bottom: solid 1px #CC111111;
//   padding-bottom: 10px;
// }

// .charger-info-table {
//   tr {
//     border-bottom: solid 1px #bbbbbb;
//   }

//   th {
//     font-weight: 400;
//     font-size: 13px;
//     line-height: 15px;
//     text-align: center;
//     color: #555555;
//     text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
//     background: #F6F6F6;
//     border-right: solid 1px #bbbbbb;
//     padding: 12px 5px;
//   }

//   td {
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 16px;
//     color: #333333;
//     padding: 12px;
//   }
// }

// .charger-label-1 {
//   background: $color6;
//   padding: 14px;
//   color: white;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 14px;
//   margin-bottom: 15px;
// }

// .charger-label-2 {
//   background: $color4;
//   padding: 14px;
//   color: white;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 14px;
//   margin-bottom: 15px;
// }

// @media (max-width: 991px) {
//   .img-charger1 {
//     width: 118px;
//   }

//   .img-charger2 {
//     width: 70px;
//   }
// }
// //lsw end


// //imsi
// main.product-index {
//   .row-product {
//     margin-right: -10px;
//     margin-left: -10px;

//     .col-product {
//       padding-right: 10px;
//       padding-left: 10px;

//       a {
//         margin-bottom: 5px;
//       }
//     }
//   }

//   .section-filter {
//     min-width: 613px;

//     hr {
//       border-color: #bbb;
//     }

//     .list-inline-item {
//       width: 100px;
//       margin-right: 0;
//     }
//   }

//   .sign-img {
//     display: none;
//     z-index: 1;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(242, 106, 39, 0.7);
//   }
// }

// main.product-show {
//   .img-product {
//     width: 300px;
//     height: 300px;
//   }

//   .btn-order {
//     border-top: 0;
//     border-left: 0;
//     border-right: 0;
//     border-bottom: 2px solid dark-color;
//     text-align: left;
//     background-color: white;
//   }

//   .btn-cart {
//     border-bottom: 2px solid dark-color;
//   }

//   .section-product-nav {
//     .partition {
//       display: block;
//       width: 100%;
//       border-bottom: 2px solid #aaa;

//       a {
//         color: #aaa;

//         &:hover {
//           color: #aaa;
//         }
//       }

//       &.active {
//         font-weight: 600;
//         border-bottom: 2px solid dark-color;

//         a {
//           color: dark-color;

//           &:hover {
//             color: dark-color;
//           }
//         }
//       }
//     }
//   }

//   .section-ferment {
//     z-index: 2;

//     .img-ferment {
//       width: auto;
//       height: 70px;

//       @media (max-width: 991px) {
//         width: auto;
//         height: 30px;
//       }
//     }
//   }

//   .review-item {
//     .img-review {
//       margin-top: 5px;
//       width: 70px;
//       height: 70px;
//     }

//     .media-body {
//       height: 75px;
//       overflow: hidden;
//     }
//   }

//   .section-line {
//     z-index: 1;
//     margin-left: -5px;
//     margin-right: -5px;

//     @media (max-width: 991px) {
//       margin-left: -16px;
//       margin-right: -16px;
//     }

//     img {
//       width: auto;
//       height: 70px;

//       @media (max-width: 991px) {
//         width: auto;
//         height: 30px;
//       }
//     }
//   }

//   .section-contents {
//     img {
//       max-width: 100%;
//       height: auto !important;
//     }
//   }

//   .section-qna-content {
//     display: none;
//   }
// }

// .form-control {
//   display: block;
//   width: 100%;
//   height: calc(1.5em + .75rem + 2px);
//   padding: .375rem .75rem;
//   font-size: 0.9rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #495057;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #ced4da;
//   border-radius: .25rem;
//   transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// }

// .lst_sty, .lst_dot {
//   overflow:hidden;
//   //color:#666;
//   line-height: 1.6em;
//   list-style-type: none;

//   @media (max-width: 991px) {
//     padding: 0;
//   }
// }

// .lst_sty2 {
//   //overflow:hidden;
//   //color:#666;
//   line-height: 1.6em;

//   @media (max-width: 991px) {
//     padding: 0;
//   }
// }

// .lst_sty>*, .lst_dot>* {
//   position:relative;
//   padding-left:1.1em;
// }
// .lst_sty>span {
//   display:inline-block;
// }
// .lst_sty.mus>* {
//   padding-left:0.8em;
// }
// .lst_sty.no>*, .lst_dot.no>* {
//   padding-left:0;
// }
// .lst_sty>*:before, .lst_dot>*:before {
//   position:absolute;
//   left:0;
//   content:"\203B";
// }
